import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../styles/HomeScreen.css'; // Import your CSS styles
import { FaArrowLeft, FaMoneyBillAlt, FaHandshake, FaUtensils, FaCreditCard, FaClock, FaTicketAlt } from 'react-icons/fa'; // Using FontAwesome for icons
import Card_Resto2 from '../components/Card_Resto2';
import Card from '../components/Card';
import Swiper from 'react-swipeable-views';
import { useLocation } from 'react-router-dom';

const HomeScreen = () => {
  const location = useLocation();
  const { userData, userPassword } = location.state || {};

  const navigate = useNavigate();

  useEffect(() => {
    const handleBackPress = () => {
      navigate(-1); // Go back on browser history
      return true;
    };

    window.addEventListener('popstate', handleBackPress); // Mimic back handler for web
    return () => window.removeEventListener('popstate', handleBackPress);
  }, [navigate]);

  useEffect(() => {
    if (userData) {
      console.log("User Data on Home Page:", userData);
    } else {
      console.warn("No user data found on home page.");
    }
  }, [userData]);

  const handleLogout = () => {
    localStorage.removeItem('userData'); // Clear stored user data
    navigate('/', { replace: true }); // Redirect to login page
};

  

  return (
    <div className="homescreen_home_scroll-container1">
      {/* Header section */}
      <div className="homescreen_home_gradient-header"> {/* Apply gradient through CSS */}
        <div className="homescreen_home_header-content">
          <div className="homescreen_home_header-row">
            <div className="homescreen_home_header-container">
              <button onClick={() => navigate(-1)} className="homescreen_home_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="homescreen_home_header-title">Acceuil</h1>
            </div>
          </div>

          <div className="homescreen_home_header-extra-space"></div>
        </div> 
      </div>

      <div className="homescreen_home_slider-container">
          <div className="homescreen_home_slide">
          <Card_Resto2 userData={userData} />
          </div>
      </div>

      {/* Body section */}
      <div className="homescreen_home_body-container1">
        {/* <div className="card-section">
          <div className="restaurant-card"></div>
        </div> */}

        {/* <div className="slider-container">
        <Swiper>
          <div className="slide">
            <Card />
          </div>
          <div className="slide">
            <QRCard />
          </div>
        </Swiper>
      </div> */}

        <h2 className="homescreen_home_section-header">Services</h2>

        {/* Services Section */}
        <div className="homescreen_home_category-container">
          {/* <div className="category-btn" onClick={() => navigate('/restauration-buy')}>
            <div className="category-icon">
              <img src={require('../assets/give-money.png')} alt="Logo" className="logo" />
            </div>
            <span className="category-btn-txt">Achat de Tickets</span>
          </div> */}

        <div className="homescreen_home_category-btn" onClick={() => navigate('/resto', { state: { userData, userPassword } })}>
            <div className="homescreen_home_category-icon">
              <img src={require('../assets/restaurant.png')} alt="Logo" className="homescreen_home_logo1" />
            </div>
            <span className="homescreen_home_category-btn-txt">Resto</span>
          </div>

          <div className="homescreen_home_category-btn" onClick={() => navigate('/awaiting2')}>
            <div className="homescreen_home_category-icon">
              <img src={require('../assets/rent.png')} alt="Logo" className="homescreen_home_logo2" />
            </div>
            <span className="homescreen_home_category-btn-txt">Loyer</span>
          </div>

          <div className="homescreen_home_category-btn" onClick={() => navigate('/settings', { state: { userData, userPassword } })}>
            <div className="homescreen_home_category-icon">
              <img src={require('../assets/gear.png')} alt="Logo" className="homescreen_home_logo3" />
            </div>
            <span className="homescreen_home_category-btn-txt">Params</span>
          </div>
        </div>

        <div className="homescreen_home_category-container">
          <div className="homescreen_home_category-btn" onClick={handleLogout}>
            <div className="homescreen_home_category-icon">
              <img src={require('../assets/logout.png')} alt="Logo" className="homescreen_home_logo4" />
            </div>
            <span className="homescreen_home_category-btn-txt">Logout</span>
          </div>

          {/* <div className="homescreen_home_category-btn" onClick={() => navigate('/resto_horaire')}>
            <div className="homescreen_home_category-icon">
              <img src={require('../assets/horaires.png')} alt="Logo" className="homescreen_home_logo5" />
            </div>
            <span className="homescreen_home_category-btn-txt">Horaires Resto</span>
          </div>

          <div className="homescreen_home_category-btn" onClick={() => navigate('/awaiting')}>
            <div className="homescreen_home_category-icon">
              <img src={require('../assets/monitor.png')} alt="Logo" className="homescreen_home_logo6" />
            </div>
            <span className="homescreen_home_category-btn-txt">Suivi Tickets</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
