import React from 'react';
import './Card_Resto.css'; // Ensure to create this CSS file for styles

const Card_Resto = ({ userData, tickets }) => {
  return ( 
    <div className="card_resto_card">
      <div className="card_resto_card-icon">
        <img src={require('../assets/crousd.png')} alt="Icon" className="card_resto_card-icon-img" />
        <h2 className="card_resto_card-holder-name">Diamniadio</h2>
        <h3 className="card_resto_card-holder-name-small">UAM</h3>
        <h5 className="card_resto_card-holder-department">{userData.user_institute}</h5>
        <h4 className="card_resto_card-holder-level">{userData.user_major}</h4>
      </div>
      <div className="card_resto_card-number">
        <p className="card_resto_card-number-text">Petit Déjeuner: {tickets.ticket_petit_dej || 0} - Repas: {tickets.ticket_repas || 0}</p>
      </div>
      <div className="card_resto_card-footer">
        <div className="card_resto_card-contact">
          <p className="card_resto_card-email">{userData.user_email}</p>
          <p className="card_resto_card-phone">{userData.user_phone}</p>
        </div>
        <p className="card_resto_card-id">{userData.user_cp}</p>
      </div>
    </div>
  );
};

export default Card_Resto;
