import { createSlice } from "@reduxjs/toolkit";

// Import images directly (assuming your assets folder is correctly located)
import ticketPetitDej from '../assets/ticket_petit_dej.jpg';
import ticketRepas from '../assets/ticket_repas.jpg';

export const CartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [
      { id: 1, title: "TICKET(S) PETIT DEJEUNER", price: 50, quantity: 0, img_source: ticketPetitDej },
      { id: 2, title: "TICKET(S) REPAS", price: 100, quantity: 0, img_source: ticketRepas },
    ],
  },
  reducers: {
    addToCart: (state, action) => {
      const itemPresent = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (itemPresent) {
        itemPresent.quantity++;
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
    },
    setQuantity: (state, action) => {
      const { itemId, quantity } = action.payload;
      const itemToUpdate = state.cart.find(item => item.id === itemId);
      if (itemToUpdate) {
        itemToUpdate.quantity = quantity;
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(
        (item) => item.id !== action.payload.id
      );
    },
    incementQuantity: (state, action) => {
      const itemPresent = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (itemPresent) {
        itemPresent.quantity++;
      }
    },
    decrementQuantity: (state, action) => {
      const itemPresent = state.cart.find(
        (item) => item.id === action.payload.id
      );
      if (itemPresent && itemPresent.quantity > 0) {
        itemPresent.quantity--;
      }
    },
    cleanCart: (state) => {
      state.cart = [];
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  incementQuantity,
  decrementQuantity,
  cleanCart,
  setQuantity,
} = CartSlice.actions;

export default CartSlice.reducer;
