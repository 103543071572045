import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../styles/Awaiting.css'
import { FaArrowLeft, FaMoneyBillAlt, FaHandshake, FaUtensils, FaCreditCard, FaClock, FaTicketAlt } from 'react-icons/fa'; // Using FontAwesome for icons
import Card_Resto from '../components/Card_Resto';
import Swiper from 'react-swipeable-views';

const Awaiting = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackPress = () => {
      navigate(-1); // Go back on browser history
      return true;
    };

    window.addEventListener('popstate', handleBackPress); // Mimic back handler for web
    return () => window.removeEventListener('popstate', handleBackPress);
  }, [navigate]);

  return (
    <div className="awaiting_scroll-container1">
      {/* Header section */}
      <div className="awaiting_gradient-header"> {/* Apply gradient through CSS */}
        <div className="awaiting_header-content">
          <div className="awaiting_header-row">
            <div className="awaiting_header-container">
              <button onClick={() => navigate(-1)} className="awaiting_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="awaiting_header-title">Suivi des Tickets</h1>
            </div>
          </div>

          <div className="awaiting_header-extra-space"></div>
        </div>
      </div>

      {/* <div className="slider-container">
          <div className="slide">
            <Card_Resto />
          </div>
      </div> */}

      {/* Body section */}
      <div className="awaiting_body-container1">


        <h2 className="awaiting_section-header">Page indisponible</h2>

        {/* <div className="category-container">


        <div className="category-btn" onClick={() => navigate('/resto_card')}>
            <div className="category-icon">
              <img src={require('../assets/give-money.png')} alt="Logo" className="logo1" />
            </div>
            <span className="category-btn-txt">Achat Tickets</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/restauration-share')}>
            <div className="category-icon">
              <img src={require('../assets/agreement.png')} alt="Logo" className="logo2" />
            </div>
            <span className="category-btn-txt">Partage Tickets</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/menu')}>
            <div className="category-icon">
              <img src={require('../assets/menu.png')} alt="Logo" className="logo3" />
            </div>
            <span className="category-btn-txt">Menu Resto</span>
          </div>
        </div> */}

        {/* <div className="category-container">
          <div className="category-btn" onClick={() => navigate('/resto-card')}>
            <div className="category-icon">
              <img src={require('../assets/card_ok.png')} alt="Logo" className="logo4" />
            </div>
            <span className="category-btn-txt">Carte Resto</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/resto-horaires')}>
            <div className="category-icon">
              <img src={require('../assets/horaires.png')} alt="Logo" className="logo5" />
            </div>
            <span className="category-btn-txt">Horaires Resto</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/resto-suivi-tickets')}>
            <div className="category-icon">
              <img src={require('../assets/monitor.png')} alt="Logo" className="logo6" />
            </div>
            <span className="category-btn-txt">Suivi des Tickets</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Awaiting;
