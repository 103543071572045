import { configureStore } from "@reduxjs/toolkit";
import CartSlice from "./redux/CartSlice"; // Ensure this path is correct

const store = configureStore({
  reducer: {
    cart: CartSlice, // Add other reducers here if needed 
  },
});

export default store;
