import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  incementQuantity,
  decrementQuantity,
  setQuantity,
  removeFromCart,
} from '../redux/CartSlice'; // Update the import based on your Redux setup
import '../styles/Resto_Tickets.css'; // Import a CSS file for styling
import { FaArrowLeft } from 'react-icons/fa'; // Icons using react-icons
import { useLocation } from 'react-router-dom';

const Resto_Tickets = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const navigate = useNavigate();

  const location = useLocation();

  const { userData, tickets1 } = location.state || {};

  const total = cart
    ?.map((item) => item.price * item.quantity)
    .reduce((curr, prev) => curr + prev, 0);

  const handleNavigate = () => {
    const petitDejeunerQuantity = cart.find((item) => item.title === "TICKET(S) PETIT DEJEUNER")?.quantity || 0;
    const repasQuantity = cart.find((item) => item.title === "TICKET(S) REPAS")?.quantity || 0;
    navigate('/resto_tickets_buy', { state: { total, petitDejeunerQuantity, repasQuantity, userData } });
  };

  const increaseQuantity = (item) => {
    dispatch(incementQuantity(item));
  };

  const decreaseQuantity = (item) => {
    dispatch(decrementQuantity(item));
  };

  const updateQuantity = (item, quantity) => {
    const parsedQuantity = quantity === '' ? 0 : parseInt(quantity, 10);
    dispatch(setQuantity({ itemId: item.id, quantity: isNaN(parsedQuantity) ? 0 : parsedQuantity }));
  };

  return (
    <div className="resto_home_scroll-container1" style={{ backgroundColor: '#59BDC7' }}>
      {/* <div className="header">
        <button className="back-button" onClick={() => navigate(-1)}>
          &larr; Back
        </button>
        <h1>Achat de Tickets</h1>
      </div> */}

<div className="resto_horaires_gradient-header"> {/* Apply gradient through CSS */}
        <div className="resto_horaires_header-content">
          <div className="resto_horaires_header-row">
            <div className="resto_horaires_header-container">
              <button onClick={() => navigate(-1)} className="resto_horaires_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="resto_horaires_header-title">Achat Tickets</h1>
            </div>
          </div>

          <div className="resto_horaires_header-extra-space"></div>
        </div>
      </div>

      <div className="resto_tickets_cart-items">
        {cart.map((item, index) => (
          <div className="resto_tickets_cart-item" key={index}>
            <img src={item.img_source} alt={item.title} className="resto_tickets_item-image" />
            <div className="resto_tickets_item-details">
              {/* <h2>{item.title}</h2> */}
              {/* <p>{item.price} FCFA</p> */}
            </div>
            <div className="resto_tickets_item-quantity">
              {item.quantity > 0 ? (
                <button className="resto_tickets_quantity-btn" onClick={() => decreaseQuantity(item)}>-</button>
              ) : (
                <button className="resto_tickets_quantity-btn disabled">X</button>
              )}
              <input
                type="number"
                className="resto_tickets_quantity-input"
                value={item.quantity}
                onChange={(e) => updateQuantity(item, e.target.value)}
                min="0"
              />
              <button className="resto_tickets_quantity-btn" onClick={() => increaseQuantity(item)}>+</button>
            </div>
          </div>
        ))}
      </div>

      <div className="resto_tickets_footer">
  <div className="resto_tickets_total-container">
    {/* <div className="subtotal">
      <p>Subtotal:</p>
      <p>{total} FCFA</p>
    </div> */}
    <div className="resto_tickets_total">
      <p>Total:</p>
      <p>{total} FCFA</p>
    </div>
  </div>
  <button className="resto_tickets_checkout-btn" onClick={handleNavigate}>Valider l'achat</button>
  {/* <button className="resto_tickets_checkout-btn">Proceed to Checkout</button> */}
</div>


    </div>
  );
};

export default Resto_Tickets;
