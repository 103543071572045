import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For web navigation
import '../styles/Resto_Menu.css'; // Import your CSS file for styles
import { FaArrowLeft } from 'react-icons/fa'; // Icons using react-icons
import { useLocation } from 'react-router-dom';

const Resto_Menu = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { userData } = location.state || {};

  const [menuData, setMenuData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleBackPress = () => {
      navigate(-1); // Go back to previous page
      return true;
    }; 

    window.addEventListener('popstate', handleBackPress); // Mimic back handler for web
    return () => window.removeEventListener('popstate', handleBackPress);
  }, [navigate]);


  const API_URL = 'server.oketudiant.ousoka.sn:3031/api/fetch_menu';

  const fetch_menu = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_cp: userData?.user_cp }),
      });
  
      const data = await response.json();
      console.log("Response Data:", data);
  
      if (response.ok) {
        const formattedMenuData = {};
  
        // Process the received data to include both lunch and dinner
        for (const day in data) {
          const dayInFrench = convertDayToFrench(day);
          
          formattedMenuData[dayInFrench] = {
            lunch: data[day].lunch || 'No lunch available', // Ensure lunch is included
            diner: data[day].diner || 'No diner available', // Ensure dinner is included
          };
        }
  
        setMenuData(formattedMenuData);
      } else {
        alert(`Erreur: ${data.error}`);
      }
    } catch (error) {
      console.error('API error:', error);
      alert('Une erreur s\'est produite. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  };  
  
  const convertDayToFrench = (day) => {
    const days = {
      Monday: "Lundi",
      Tuesday: "Mardi",
      Wednesday: "Mercredi",
      Thursday: "Jeudi",
      Friday: "Vendredi",
      Saturday: "Samedi",
      Sunday: "Dimanche"
    };
    return days[day] || day; // Return the translated day or the original if not found
  };
  
  useEffect(() => {
    if (userData?.user_cp) {
      fetch_menu();
    }
  }, [userData?.user_cp]);

  // const menuData = {
  //   Lundi: { lunch: "Riz au poulet", dinner: "Lentille" },
  //   Mardi: { lunch: "Riz au poisson blanc", dinner: "Couscous/Thiéré" },
  //   Mercredi: { lunch: "Mafé/Soupou Kandia", dinner: "Vermicelle" },
  //   Jeudi: { lunch: "Riz à la viande", dinner: "Sardine Frites" },
  //   Vendredi: { lunch: "Domada/Yassa", dinner: "Couscous/Thiéré" },
  //   Samedi: { lunch: "Riz au poulet", dinner: "Hamburger" },
  //   Dimanche: { lunch: "Yassa/Thiou", dinner: "Petit pois" },
  // };

  return (
    <div className="resto_menu_scroll-container" style={{ backgroundColor: '#59BDC7' }}>
      {/* Statusbar - Can control it using meta tags */}
      
      {/* Header Section */}
      <div className="resto_horaires_gradient-header"> {/* Apply gradient through CSS */}
        <div className="resto_horaires_header-content">
          <div className="resto_horaires_header-row">
            <div className="resto_horaires_header-container">
              <button onClick={() => navigate(-1)} className="resto_horaires_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="resto_horaires_header-title">Menu Resto</h1>
            </div>
          </div>

          <div className="resto_horaires_header-extra-space"></div>
        </div>
      </div>

      {/* Body Section */}
      <div className="resto_menu_body-container">
        <div className="resto_menu_image-background">
          {/* Restaurant Menu Image */}


    <div className="resto_menu_menu-container">
      {/* <h1 className="menu-title">Menu Restaurant</h1> */}
      
      <div className="resto_menu_menu-grid">
        {Object.keys(menuData).map(day => (
          <div className="resto_menu_menu-row" key={day}>
            <div className="resto_menu_menu-day">{day}</div>
            <div className="resto_menu_menu-item lunch">{menuData[day].lunch}</div>
            <div className="resto_menu_menu-item dinner">{menuData[day].diner}</div>
          </div>
        ))}
      </div>
    </div>


        </div>
      </div>
    </div>
  );
};

export default Resto_Menu;
