import React, { useEffect, useState } from 'react';
import styles from './QRCard1.css';
import { QRCodeCanvas } from 'qrcode.react';

// const QRCard1 = () => {
const QRCard1 = ({ userData }) => {

  const [qrValue, setQRValue] = useState('');

  useEffect(() => {
    if (userData?.user_cp) {
      setQRValue(userData.user_cp);
    }
  }, [userData]);

  // useEffect(() => {
  //   generateAndSaveQRCode(); // Generate and save QR code when the component mounts
  // }, []);

  // const generateAndSaveQRCode = () => {
  //   const randomValue = generateRandomString(10);
  //   setQRValue(randomValue);
  // };

  // const generateRandomString = (length) => {
  //   const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  //   let randomString = '';
  //   for (let i = 0; i < length; i++) {
  //     const randomIndex = Math.floor(Math.random() * charset.length);
  //     randomString += charset[randomIndex];
  //   }
  //   return randomString;
  // };

  return ( 
    <div className={styles.card}>
      <div className={styles.qrCodeContainer}>
        <QRCodeCanvas value={qrValue || 'NA'} size={300} bgColor="#FFFFFF" fgColor="#000000" />
        <img src={require('../assets/crousd.png')} alt="Logo Overlay" className="logoOverlay1" />
      </div>
      <div className={styles.cardFooter}>
        {/* Add footer content here if needed */}
      </div>
    </div>
  );
};

export default QRCard1;
