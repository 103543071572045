// import React, { useState } from 'react';
// import axios from 'axios';
// import { FaArrowLeft } from 'react-icons/fa'; // Import an icon library for web
// import '../styles/Resto_Tickets_Buy.css'; // Add a CSS file for custom styles
// import { useNavigate, useLocation } from 'react-router-dom'; // For navigation
// import giveMoneyIcon from '../assets/give-money.png'; // Import the image
// import OM from '../assets/om1.png'; // Import the image
// import Wave from '../assets/wave1.png'; // Import the image

// const Resto_Tickets_Buy = ({ }) => {
//     const navigate = useNavigate();

//     const location = useLocation(); // Use useLocation to access location
//     const { total, petitDejeunerQuantity, repasQuantity, userData } = location.state || {}; // Retrieve quantities from location state

//   const [selectedCard, setSelectedCard] = useState(null);
//   const [order_payment_orange_money, setOrangeMoney] = useState('');
//   const [order_payment_wave, setWave] = useState('');

// //   const { total, petitDejeunerQuantity, repasQuantity } = location.state || {};

//   const handleCardSelect = (card) => {
//     setSelectedCard(card);
//     if (card.id === 1) {
//       setOrangeMoney(1);
//       setWave(0);
//     } else if (card.id === 2) {
//       setOrangeMoney(0);
//       setWave(1);
//     }
//   };

// //   const SubmitOrder = async () => {
// //     try {
// //       const response = await axios.put('http://localhost:3000/api/neworder', {
// //         order_payment_orange_money,
// //         order_payment_wave,
// //       });

// //       if (response.status === 200) {
// //         console.log('Order submitted successfully');
// //         history.push('/done-submit');
// //       } else {
// //         console.log('Order submission failed');
// //       }
// //     } catch (error) {
// //       console.error('Error submitting order:', error);
// //     }
// //   };

// const SubmitOrder = async () => {
//   try {
//     // Make sure you have selected a payment method
//     if (!selectedCard) {
//       alert('Veuillez sélectionner une méthode de paiement.');
//       return;
//     }

//     // Prepare the data to send
//     const response = await axios.put('http://localhost:3000/api/increment_tickets', {
//       user_cp: userData?.user_cp,
//       petitDejeunerQuantity,
//       repasQuantity,
//     });

//     if (response.status === 200) {
//       console.log('Tickets incremented successfully');
//       // navigate('/done-submit'); // Navigate to a success page or appropriate route
//     } else {
//       console.log('Failed to increment tickets:', response.data.error);
//     }
//   } catch (error) {
//     console.error('Error submitting order:', error);
//     alert('Une erreur s\'est produite lors de l\'ajout des tickets. Veuillez réessayer.');
//   }
// };


//   const myCards = [
//     { id: 1, name: 'Orange Money', icon: OM },
//     { id: 2, name: 'Wave', icon: Wave },
//   ];

//   console.log(c);
//   return (
//     <div className="resto_home_scroll-container1">
//       <div className="resto_home_gradient-header"> {/* Apply gradient through CSS */}
//         <div className="resto_home_header-content">
//           <div className="resto_home_header-row">
//             <div className="resto_home_header-container">
//               <button onClick={() => navigate(-1)} className="resto_home_back-button">
//                 <FaArrowLeft size={40} color="#FFF" />
//               </button>
//               <h1 className="resto_home_header-title">Valider Achat</h1>
//             </div>
//           </div>

//           <div className="resto_home_header-extra-space"></div>
//         </div> 
//       </div>

//       <div className="content">
//         <div className="card-summary">
//           <img src={giveMoneyIcon} alt="Give Money" className="summary-icon" />
//           <div className="summary-text">
//             {/* <p><strong>Achat:</strong> Petit-Déjeuner: {petitDejeunerQuantity}</p>
//             <p>Repas: {repasQuantity}</p> */}
//             <p> Petit-Déjeuner: {petitDejeunerQuantity}</p>
//             <p>Repas: {repasQuantity}</p>
//           </div>
//         </div>

//         <p className="summary-text1">Vous avez désormais la possibilité de sélectionner votre méthode de paiement :</p>

//         <div className="card-options">
//           {myCards.map((item) => (
//             <div
//               key={item.id}
//               className={`card-item ${selectedCard?.id === item.id ? 'selected' : ''}`}
//               onClick={() => handleCardSelect(item)}
//             >
//               <img src={item.icon} alt={item.name} className="card-icon" />
//               {/* <p>{item.name}</p> */}
//             </div>
//           ))}
//         </div>

//         {/* <button className="submit-button"> */}
//         <button className="submit-button" onClick={SubmitOrder}>
//           Confirmer le Paiement
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Resto_Tickets_Buy;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import '../styles/Resto_Tickets_Buy.css';
import { useNavigate, useLocation } from 'react-router-dom';
import giveMoneyIcon from '../assets/give-money.png';
import OM from '../assets/om1.png';
import Wave from '../assets/wave1.png';

const Resto_Tickets_Buy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { petitDejeunerQuantity, repasQuantity, userData } = location.state || {};

  const [selectedCard, setSelectedCard] = useState(null);
  const [oldValues, setOldValues] = useState({ ticket_petit_dej: 0, ticket_repas: 0 });
  const [newValues, setNewValues] = useState(null); // to store updated values after API response

  useEffect(() => {
      // Fetch the current values of tickets when component mounts
      const fetchOldValues = async () => {
          try {
              const response = await axios.get(`server.oketudiant.ousoka.sn:3031/api/tickets/${userData?.user_cp}`);
              if (response.status === 200) {
                  setOldValues(response.data);
              } else {
                  console.log('Error fetching old ticket values:', response.data.error);
              }
          } catch (error) {
              console.error('Error fetching old ticket values:', error);
          }
      };

      fetchOldValues();
  }, [userData?.user_cp]);

  const handleCardSelect = (card) => {
      setSelectedCard(card);
  };

  const SubmitOrder = async () => {
    try {
      if (!selectedCard) {
        alert('Veuillez sélectionner une méthode de paiement.');
        return;
      }
  
      const response = await axios.put('server.oketudiant.ousoka.sn:3031/api/increment_tickets', {
        user_cp: userData?.user_cp,
        petitDejeunerQuantity,
        repasQuantity,
      });
  
      if (response.status === 200) {
        console.log('Tickets incremented successfully:', response.data.updatedValues);
        alert('Tickets incremented successfully');
        setNewValues(response.data.updatedValues); // Update state with new values
        navigate('/resto', { state: { userData } });
      } else {
        console.log('Error response from server:', response.data.error);
        alert(`Erreur: ${response.data.error}`);
      }
    } catch (error) {
      alert('Une erreur s\'est produite lors de l\'ajout des tickets. Veuillez réessayer.');
      console.error('Error submitting order:', error.message);
    }
  };
    

    const myCards = [
        { id: 1, name: 'Orange Money', icon: OM },
        { id: 2, name: 'Wave', icon: Wave },
    ];


    console.log('user_cp:', userData?.user_cp);
console.log('petitDejeunerQuantity:', petitDejeunerQuantity);
console.log('repasQuantity:', repasQuantity);

    return (
        <div className="resto_home_scroll-container1">
            <div className="resto_home_gradient-header">
                <div className="resto_home_header-content">
                    <div className="resto_home_header-row">
                        <div className="resto_home_header-container">
                            <button onClick={() => navigate(-1)} className="resto_home_back-button">
                                <FaArrowLeft size={40} color="#FFF" />
                            </button>
                            <h1 className="resto_home_header-title">Valider Achat</h1>
                        </div>
                    </div>
                    <div className="resto_home_header-extra-space"></div>
                </div>
            </div>

            <div className="content">
                <div className="card-summary">
                    <img src={giveMoneyIcon} alt="Give Money" className="summary-icon" />
                    <div className="summary-text">
                        <p>Petit-Déjeuner: {petitDejeunerQuantity}</p>
                        <p>Repas: {repasQuantity}</p>
                    </div>
                </div>

                <p className="summary-text1">Vous avez désormais la possibilité de sélectionner votre méthode de paiement :</p>

                <div className="card-options">
                    {myCards.map((item) => (
                        <div
                            key={item.id}
                            className={`card-item ${selectedCard?.id === item.id ? 'selected' : ''}`}
                            onClick={() => handleCardSelect(item)}
                        >
                            <img src={item.icon} alt={item.name} className="card-icon" />
                        </div>
                    ))}
                </div>

                <button className="submit-button" onClick={SubmitOrder}>
                    Confirmer le Paiement
                </button>
            </div>
        </div>
    );
};

export default Resto_Tickets_Buy;
