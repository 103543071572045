import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../styles/Resto_Card.css'; // Import your CSS styles
import { FaArrowLeft, FaMoneyBillAlt, FaHandshake, FaUtensils, FaCreditCard, FaClock, FaTicketAlt } from 'react-icons/fa'; // Using FontAwesome for icons
import Card_Resto1 from '../components/Card_Resto1';
import Swiper from 'react-swipeable-views';
import QRCard1 from '../components/QRCard1';
import { useLocation } from 'react-router-dom';

const Resto_Card = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userData, tickets1 } = location.state || {};


  useEffect(() => {
    const handleBackPress = () => {
      navigate(-1); // Go back on browser history
      return true;
    };

    window.addEventListener('popstate', handleBackPress); // Mimic back handler for web
    return () => window.removeEventListener('popstate', handleBackPress);
  }, [navigate]);

  return (
    <div className="resto_card_scroll-container1">
      {/* Header section */}
      <div className="resto_card_gradient-header"> {/* Apply gradient through CSS */}
        <div className="resto_card_header-content">
          <div className="resto_card_header-row">
            <div className="resto_card_header-container">
              <button onClick={() => navigate(-1)} className="resto_card_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="resto_card_header-title">Carte Restaurant</h1>
            </div>
          </div>

          <div className="resto_card_header-extra-space"></div>
        </div>
      </div>

      <div className="resto_card_slider-container">
          <div className="resto_card_slide">
            <Card_Resto1 userData={userData} tickets={tickets1}/>
          </div>
      </div>

      {/* Body section */}
      <div className="resto_card_body-container1">
        {/* <div className="card-section">
          <div className="restaurant-card"></div>
        </div> */}

        {/* <div className="slider-container">
        <Swiper>
          <div className="slide">
            <Card />
          </div>
          <div className="slide">
            <QRCard />
          </div> 
        </Swiper>
      </div> */}

        <h2 className="resto_card_section-header">Code QR Resto</h2>

        {/* Services Section */}

        <div className="resto_card_slider-container1">
              <QRCard1 userData={userData}/>
        </div>

        {/* <div className="category-container"> */}
          {/* <div className="category-btn" onClick={() => navigate('/restauration-buy')}>
            <div className="category-icon">
              <img src={require('../assets/give-money.png')} alt="Logo" className="logo" />
            </div>
            <span className="category-btn-txt">Achat de Tickets</span>
          </div> */}

        {/* <div className="category-btn" onClick={() => navigate('/resto_card')}>
            <div className="category-icon">
              <img src={require('../assets/give-money.png')} alt="Logo" className="logo1" />
            </div>
            <span className="category-btn-txt">Achat Tickets</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/restauration-share')}>
            <div className="category-icon">
              <img src={require('../assets/agreement.png')} alt="Logo" className="logo2" />
            </div>
            <span className="category-btn-txt">Partage Tickets</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/menu')}>
            <div className="category-icon">
              <img src={require('../assets/menu.png')} alt="Logo" className="logo3" />
            </div>
            <span className="category-btn-txt">Menu Resto</span>
          </div> */}
        {/* </div> */}

        {/* <div className="category-container">
          <div className="category-btn" onClick={() => navigate('/resto-card')}>
            <div className="category-icon">
              <img src={require('../assets/card_ok.png')} alt="Logo" className="logo4" />
            </div>
            <span className="category-btn-txt">Carte Resto</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/resto_horaire')}>
            <div className="category-icon">
              <img src={require('../assets/horaires.png')} alt="Logo" className="logo5" />
            </div>
            <span className="category-btn-txt">Horaires Resto</span>
          </div>

          <div className="category-btn" onClick={() => navigate('/awaiting')}>
            <div className="category-icon">
              <img src={require('../assets/monitor.png')} alt="Logo" className="logo6" />
            </div>
            <span className="category-btn-txt">Suivi des Tickets</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Resto_Card;
