import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../styles/Resto_Home.css'; // Import your CSS styles
import { FaArrowLeft, FaMoneyBillAlt, FaHandshake, FaUtensils, FaCreditCard, FaClock, FaTicketAlt } from 'react-icons/fa'; // Using FontAwesome for icons
import Card_Resto from '../components/Card_Resto';
import Swiper from 'react-swipeable-views';
import { useLocation } from 'react-router-dom';

const Resto_Home = () => {
  const navigate = useNavigate();

  const [tickets1, setTickets] = useState({ ticket_petit_dej: 0, ticket_repas: 0 });
  const [isLoading, setIsLoading] = useState(false);


  const location = useLocation();
  const { userData, userPassword } = location.state || {};

  const API_URL = 'server.oketudiant.ousoka.sn:3031/api/fetch_tickets';

  const fetch_tickets = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_cp: userData?.user_cp}),
      });
  
      const data = await response.json();
      console.log("Response Data:", data); // Log entire response
  
      if (response.ok) {
        const { ticket_petit_dej, ticket_repas } = data.tickets;
        setTickets({ ticket_petit_dej, ticket_repas });
        // if (tickets) {
        //   console.log("Tickets Received:", tickets); // Log user data
        //   setTickets(tickets);
        // } else {
        //   console.error("No user data found in response.");
        //   alert("User data not received from server.");
        // }
      } else {
        alert(`Erreur: ${data.error}`);
      }
    } catch (error) {
      console.error('API error:', error);
      alert('Une erreur s\'est produite. Veuillez réessayer plus tard.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleBackPress = () => {
      navigate(-1); // Go back on browser history
      return true;
    };

    window.addEventListener('popstate', handleBackPress); // Mimic back handler for web
    return () => window.removeEventListener('popstate', handleBackPress);
  }, [navigate]);

  
  // Fetch tickets when the component mounts
  useEffect(() => {
    if (userData?.user_cp) {
      fetch_tickets();
    }
  }, [userData?.user_cp]);

  return (
    <div className="resto_home_scroll-container1">
      {/* Header section */}
      <div className="resto_home_gradient-header"> {/* Apply gradient through CSS */}
        <div className="resto_home_header-content">
          <div className="resto_home_header-row">
            <div className="resto_home_header-container">
              <button onClick={() => navigate(-1)} className="resto_home_back-button">
                <FaArrowLeft size={40} color="#FFF" />
              </button>
              <h1 className="resto_home_header-title">Restauration</h1>
            </div>
          </div>

          <div className="resto_home_header-extra-space"></div>
        </div> 
      </div>

      <div className="resto_home_slider-container">
          <div className="resto_home_slide">
          <Card_Resto userData={userData} tickets={tickets1} />
          </div>
      </div>

      {/* Body section */}
      <div className="resto_home_body-container1">
        {/* <div className="card-section">
          <div className="restaurant-card"></div>
        </div> */}

        {/* <div className="slider-container">
        <Swiper>
          <div className="slide">
            <Card />
          </div>
          <div className="slide">
            <QRCard />
          </div>
        </Swiper>
      </div> */}

        <h2 className="resto_home_section-header">Resto</h2>

        {/* Services Section */}
        <div className="resto_home_category-container">
          {/* <div className="category-btn" onClick={() => navigate('/restauration-buy')}>
            <div className="category-icon">
              <img src={require('../assets/give-money.png')} alt="Logo" className="logo" />
            </div>
            <span className="category-btn-txt">Achat de Tickets</span>
          </div> */}

        <div className="resto_home_category-btn" onClick={() => navigate('/resto_card', { state: { userData, tickets1 } })}>
            <div className="resto_home_category-icon">
              <img src={require('../assets/give-money.png')} alt="Logo" className="resto_home_logo1" />
            </div>
            <span className="resto_home_category-btn-txt">Achat Tickets</span>
          </div>

          <div className="resto_home_category-btn" onClick={() => navigate('/awaiting1')}>
            <div className="resto_home_category-icon">
              <img src={require('../assets/agreement.png')} alt="Logo" className="resto_home_logo2" />
            </div>
            <span className="resto_home_category-btn-txt">Partage Tickets</span>
          </div>

          <div className="resto_home_category-btn" onClick={() => navigate('/menu', { state: { userData } })}>
            <div className="resto_home_category-icon">
              <img src={require('../assets/menu.png')} alt="Logo" className="resto_home_logo3" />
            </div>
            <span className="resto_home_category-btn-txt">Menu Resto</span>
          </div>
        </div>

        <div className="resto_home_category-container">
          <div className="resto_home_category-btn" onClick={() => navigate('/resto_card1', { state: { userData, tickets1 } })}>
            <div className="resto_home_category-icon">
              <img src={require('../assets/card_ok.png')} alt="Logo" className="resto_home_logo4" />
            </div>
            <span className="resto_home_category-btn-txt">Carte Resto</span>
          </div>

          <div className="resto_home_category-btn" onClick={() => navigate('/resto_horaire')}>
            <div className="resto_home_category-icon">
              <img src={require('../assets/horaires.png')} alt="Logo" className="resto_home_logo5" />
            </div>
            <span className="resto_home_category-btn-txt">Horaires Resto</span>
          </div>

          <div className="resto_home_category-btn" onClick={() => navigate('/awaiting')}>
            <div className="resto_home_category-icon">
              <img src={require('../assets/monitor.png')} alt="Logo" className="resto_home_logo6" />
            </div>
            <span className="resto_home_category-btn-txt">Suivi Tickets</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resto_Home;
