// // src/navigators/index.js
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import WelcomeScreen from './screens/WelcomeScreen';
// import HomeScreen from './screens/HomeScreen';
// import Resto_Home from './screens/Resto_Home';
// import Resto_Menu from './screens/Resto_Menu';
// import Resto_Tickets from './screens/Resto_Tickets';

// function Navigators() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<WelcomeScreen />} />
//         <Route path="/home" element={<HomeScreen />} />
//         <Route path="/resto" element={<Resto_Home />} />
//         <Route path="/menu" element={<Resto_Menu />} />
//         <Route path="/resto_card" element={<Resto_Tickets />} />
//       </Routes>
//     </Router>
//   );
// }

// export default Navigators;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomeScreen from './screens/WelcomeScreen';
import HomeScreen from './screens/HomeScreen';
import Resto_Home from './screens/Resto_Home';
import Resto_Menu from './screens/Resto_Menu';
import Resto_Tickets from './screens/Resto_Tickets';
import { Provider } from 'react-redux'; // Redux provider import
import store from './store'; // Import your Redux store
import Awaiting from './screens/Awaiting';
import Awaiting1 from './screens/Awaiting1';
import Resto_Horaires from './screens/Resto_Horaires';
import Resto_Card from './screens/Resto_Card';
import Resto_Tickets_Buy from './screens/Resto_Tickets_Buy';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import Awaiting2 from './screens/Awaiting2';
import Settings from './screens/Settings';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<WelcomeScreen />} />
          <Route path="/" element={<ProtectedRoute><WelcomeScreen /></ProtectedRoute>} />

          <Route path="/home" element={<HomeScreen />} />
          <Route path="/resto" element={<Resto_Home />} />
          <Route path="/menu" element={<Resto_Menu />} />
          <Route path="/resto_card" element={<Resto_Tickets />} />
          <Route path="/awaiting" element={<Awaiting />} />
          <Route path="/awaiting1" element={<Awaiting1 />} />
          <Route path="/awaiting2" element={<Awaiting2 />} />
          <Route path="/resto_horaire" element={<Resto_Horaires />} />
          <Route path="/resto_card1" element={<Resto_Card />} />
          <Route path="/resto_tickets_buy" element={<Resto_Tickets_Buy />} />
          <Route path="/settings" element={<Settings />} />

          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;


